import { createApp } from 'vue'

// App

import App from './App.vue'
const app = createApp(App)

// Router

import router from './router'
app.use(router)

router.beforeEach((to) => {
  const host = window.location.host
  if (host != 'lis.dev' && host != 'localhost:8080') {
    window.location.href = 'https://lis.dev/'
  }

  document.title = `Aleksandr lis — 3D artist`
})

// Render

app.mount('#app')
