import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', name: 'Home', component: () => import('@/views/Home.vue') },
  { path: '/games-privacy-policy', name: 'PrivacyPolicy', component: () => import('@/views/GamesPrivacyPolicy.vue') },
  { path: '/cv', name: 'CV', component: () => import('@/views/CV.vue') },
  { path: '/days', name: 'Days', component: () => import('@/views/Days.vue') },
  { path: '/:catchAll(.*)', name: 'not_found', component: () => import('@/views/NotFound.vue') },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
